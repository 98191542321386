<template>
  <div class="px-3">
    <vs-table
      v-if="owners.length > 0"
      :data="owners"
      search
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h2 class="text-dark ml-4">
            Owners
          </h2>
          <vs-button
            color="#66ff66"
            text-color="black"
            class="mr-3"
            icon="add"
            @click="createNewOwner()"
          >
            Adicionar Owner
          </vs-button>
        </div>
      </template>
      <template slot="thead">
        <vs-th class="text-center">
          <div class="text-center">
            ID
          </div>
        </vs-th>
        <vs-th>Nome</vs-th>
        <vs-th>
          <div class="w-100 text-center">
            Ações
          </div>
        </vs-th>
        <vs-th>
          <div class="w-100 text-center">
            Gestão do Fanbox
          </div>
        </vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(owner, i) in data"
          :key="i"
          class="border-top"
          :data="owner"
        >
          <vs-td class="py-1">
            {{ owner.id }}
          </vs-td>
          <vs-td class="py-1">
            {{ owner.entityName }}
          </vs-td>
          <vs-td class="py-1">
            <div class="w-100 d-flex justify-content-center">
              <vs-button
                small
                icon="edit"
                @click="editOwner(owner)"
              />
            </div>
            <!-- <vs-button small icon="clear"></vs-button> -->
          </vs-td>
          <vs-td class="py-1 d-flex justify-content-center">
            <vs-button
              small
              icon="dashboard"
              @click="goToFanboxManagement(owner.id)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      :title="editingOwner ? 'Alterar Owner' : 'Adicionar novo Owner'"
      :active.sync="ownerDialog"
    >
      <vs-row>
        <vs-col vs-lg="2">
          <label class="small font-weight-bold mt-2">ID:</label>
          <vs-input
            v-if="editingOwner"
            v-model="obj.id"
            placeholder="ID"
            class="w-100"
            disabled
          />
          <vs-input
            v-else
            v-model="obj.id"
            placeholder="ID"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="5">
          <label class="small font-weight-bold mt-2">Nome da entidade:</label>
          <vs-input
            v-model="obj.entityName"
            placeholder="Nome da entidade"
            class="w-100"
          />
        </vs-col>
        <vs-col vs-lg="5">
          <label class="small font-weight-bold mt-2">Apelido do Owner:</label>
          <vs-input
            v-model="obj.nickName"
            placeholder="Apelido"
            class="w-100"
          />
        </vs-col>
      </vs-row>
      <div class="d-flex justify-content-end mt-3">
        <vs-button
          class="mr-2"
          @click="ownerDialog = false"
        >
          Cancelar
        </vs-button>
        <vs-button
          color="#66ff66"
          text-color="black"
          @click="saveOwner()"
        >
          <span v-if="editingOwner">Alterar</span>
          <span v-else>Salvar</span>
          Owner
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Config */
import { baseUrl } from '@/global';

/* Services */
import axios from 'axios';
import System from '@/services/system';

const systemService = new System();

export default {
  name: 'OwnerList',
  data: () => ({
    title: 'SurvTable',
    owners: [],
    obj: {},
    ownerDialog: false,
    editingOwner: false,
  }),
  mounted() {
    this.getOwners();
  },
  methods: {
    getOwners() {
      this.$store.dispatch('set_isLoadingActive', true);

      systemService
        .getOwners()
        .then((res) => {
          this.owners = res.data.data;

          this.owners.sort(function (a, b) {
            return a.id - b.id;
          })

          this.$store.dispatch('set_isLoadingActive', false);
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        });
    },
    editOwner(owner) {
      this.ownerDialog = true;
      this.editingOwner = true;
      this.obj = owner;
    },
    createNewOwner() {
      this.obj = {};
      this.editingOwner = false;
      this.ownerDialog = true;
    },
    saveOwner() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/Owners`;
      const object = this.obj;

      object.id = parseInt(object.id);

      axios
        .post(url, object)
        .then(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          let term = this.editingOwner ? 'atualizado' : 'criado';

          this.$vs.notify({
            title: 'Sucesso',
            color: 'primary',
            text: `Owner ${term} com sucesso`,
          });
        })
        .catch((err) => {
          this.$store.dispatch('set_isLoadingActive', false);
          alert(err);
        })
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);

          this.ownerDialog = false;
          this.editingOwner = false;
          this.getOwners();
        });
    },
    goToFanboxManagement(id) {
      this.$router.push({
        name: 'FanboxManagement',
        params: { selectedOwner: id },
      });
    },
  },
};
</script>
